import './index.scss'
import React from "react";

const GitHubProjectCard = ({ repo }) => {
    return (
        <div className="card" style={styles.card}>
            <div className="card-content" style={styles.cardContent}>
                <h3 style={styles.title}>{repo.name}</h3>
                <p style={styles.description}>{repo.description || "No description available."}</p>
                <p style={styles.language}>Language: {repo.language}</p>
            </div>
            <div className="card-footer" style={styles.cardFooter}>
                <a className='flat-button' href={repo.html_url} target="_blank" rel="noopener noreferrer">
                    View on GitHub
                </a>
            </div>
        </div>
    );
};

const styles = {
    card: {
        backgroundColor: "#f8f1ca",
        borderRadius: "10px",
        border: "2px solid #738d43",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        width: "400px",
        margin: "10px",
    },
    cardContent: {
        paddingBottom: "20px",
    },
    title: {
        fontSize: "1.25rem",
        fontWeight: "bold",
    },
    language: {
        color: "#6d6d6d"
    },
    description: {
        color: "#000",
    },
    cardFooter: {
        paddingTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    link: {
        padding: "5px",
        borderRadius: "5px",
        border: "2px solid #738d43",
        color: "#738d43",
        textDecoration: "none",
    },
};

export default GitHubProjectCard;
