import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState} from 'react'
import { Loader } from 'react-loaders'
import GitHubProjectCard from './githubcards'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

const script = document.createElement("script"); script.src = "//cdn.jsdelivr.net/github-cards/latest/widget.js"; document.body.appendChild(script); 

const [repos, setRepos] = useState([]);

useEffect(() => {
  fetch("https://api.github.com/users/AMR1234567891011/repos")
    .then(response => response.json())
    .then(data => {
        if (Array.isArray(data)) {
            const filteredRepos = data.filter(repo => repo.topics.includes('portfolio'));
            setRepos(filteredRepos);
        } else {
            console.error("Received data is not an array", data);
        }
    })
    .catch((error) => {
        console.error("Error fetching data from GitHub:", error);
    });
}, []);
    return (
        <>
        <div className='container about-page'>
            <div className='Squishy'>
                <div className='text-zone' id="about-text">
                    <h1>
                        <AnimatedLetters
                            strArray={['A','b','o','u','t',' ','M','e']}
                            letterClass={letterClass}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I am a student at The Universiy of Tulsa studying for three majors:
                        Computer Science, Data Science, and Mathematics. In each of my projects
                        I strive to learn the very basics of a new type of development. 
                        I am currently working as a undergradute security researcher on a federal grant at my university.
                    </p>
                    <p>
                        I am the vice president and treasurer for The University of Tulsa Association of Computing Machinery(ACM) chapter. 
                        We run events on software development, programming languages, work culture,
                        and interview preparation. ACM-TU has also worked with Paycom to highlight their Oklahoma based development team.
                    </p>
                    <p>
                        Outside of school and work I have completed the rank of Eagle Scout, and have held leadership
                        positions in Scouting and highschool clubs. I also love playing recreational sports like pickleball.
                    </p>
                        <a className='flat-button' href='/ASHER-ROSEN-2-26-2024.pdf' target="_blank" rel="noopener noreferrer">
                            RESUME
                        </a>

                </div>

                <div className="card" id="about-card">
                    <h1 className="text-2xl font-bold">Pinned Projects</h1>
                        {repos.map((repo) => (
                            <GitHubProjectCard key={repo.id} repo={repo} />
                        ))}
                </div>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About